import styles from './Locations.module.scss';
import smallCheckFilled from '@moonshineragency/ui/src/assets/tokens/icons/icons-small/smallCheckFilled.svg';
import smallClockSoon from '@moonshineragency/ui/src/assets/tokens/icons/icons-small/smallClockSoon.svg';
import React from 'react';
import { unstable_FormRadio as FormRadio } from 'reakit/Form';
import Radio from '@moonshineragency/ui/src/components/Radio/Radio';
import Image from '@moonshineragency/ui/src/components/Image/Image';
import Heading from '@moonshineragency/ui/src/components/Heading/Heading';

const TemplateLocation = ({
  location,
  isDisabled,
  form,
  locationName,
  unit,
}) => {
  const convertStatus = status => {
    if (status === 'recruiting') {
      return 'Recruiting';
    }
    if (status === 'recruiting-soon') {
      return 'Recruiting soon';
    }
    if (status === 'not-recruiting') {
      return 'Not recruiting';
    }
    if (status === 'ended') {
      return 'Ended';
    }
    if (status === 'active') {
      return 'Active';
    }
    return 'By invitation';
  };

  const locationAdress = location?.address?.state_province
    ? `${location?.address?.city}, ${location?.address?.state_province}, ${location?.address?.country}`
    : `${location?.address?.city},  ${location?.address?.country}`;

  const locationNameLabel = (
    <Heading theme="primary" size="h5" noSpacing>
      {location?.name}
    </Heading>
  );

  return (
    <div>
      <FormRadio
        as={Radio}
        {...form}
        name="trial_location_id"
        value={location?.id}
        label={locationNameLabel}
        disabled={isDisabled}
        theme={isDisabled && 'disabledLabel'}
        className={styles.locationRadioButtons}
      />
      <div
        className={isDisabled ? styles.disabledContent : styles.institutWrapper}
      >
        <div className={styles.instituteContact}>{locationAdress}</div>
        <div className={styles.instituteContact}>{location?.contact?.name}</div>
        <div className={styles.instituteAddressAndStatusWrapper}>
          {locationName && location.distance > 0 && (
            <div className={styles.instituteAddress}>
              {Math.round(location.distance)} {unit} from{' '}
              {locationName.charAt(0).toUpperCase() + locationName.slice(1)}
            </div>
          )}
          <div className={styles.recruitingStatus}>
            {/* TODO: update icon */}
            <Image
              src={
                location?.recruitment_status === 'recruiting'
                  ? smallCheckFilled
                  : smallClockSoon
              }
              alt="recruiting_icon"
              className={
                location?.recruitment_status === 'recruiting'
                  ? styles.redIcon
                  : styles.recruitingIcon
              }
            />
            {convertStatus(location?.recruitment_status) || ''}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TemplateLocation;
