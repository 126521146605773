import styles from 'templates/contact/contact.module.scss';
import useTranslation from 'hooks/useTranslation';
import Container from 'components/Container/Container';
import Layout from 'components/Layout/Layout';
import Locations from 'templates/contact/components/Locations/Locations';
import InjuryLevel from 'components/InjuryLevel/InjuryLevel';
import InjurySeverity from 'components/InjurySeverity/InjurySeverity';
import TimeSinceInjury from 'components/TimeSinceInjury/TimeSinceInjury';
import Selector from 'templates/contact/components/Selector/Selector';
import Client from 'api/client';
import { SearchSettingsContext } from 'context';
import useQueryParams from 'hooks/useQueryParams';
import IconComponents from '@moonshineragency/ui/src/components/IconComponents/IconComponents';
import IconWrapper from '@moonshineragency/ui/src/components/IconWrapper/IconWrapper';
import { navigate } from 'gatsby';
import Form, {
  renderInputErrorFor,
} from '@moonshineragency/ui/src/components/Form/Form';
import {
  unstable_useFormState as useFormState,
  unstable_FormSubmitButton as FormSubmitButton,
  unstable_FormMessage as FormMessage,
  unstable_FormCheckbox as FormCheckbox,
  unstable_FormRadioGroup as FormRadioGroup,
  unstable_FormRadio as FormRadio,
} from 'reakit/Form';
import { usePopoverState } from 'reakit/Popover';
import Radio from '@moonshineragency/ui/src/components/Radio/Radio';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { useCheckboxState } from 'reakit/Checkbox';
import React, { useRef, useEffect, useState, useLayoutEffect } from 'react';

import Textarea from '@moonshineragency/ui/src/components/Textarea/Textarea';
import Heading from '@moonshineragency/ui/src/components/Heading/Heading';
import Content from '@moonshineragency/ui/src/components/Content/Content';
import Link from '@moonshineragency/ui/src/components/Link/Link';
import Button from '@moonshineragency/ui/src/components/Button/Button';
import InputField from '@moonshineragency/ui/src/components/InputField/InputField';
import Checkbox from '@moonshineragency/ui/src/components/Checkbox/Checkbox';
import classNames from 'classnames';

const ContactTemplate = () => {
  const context = React.useContext(SearchSettingsContext);
  const contextForm = context?.form;
  const formValues = contextForm?.values;
  const [locationName, setLocationName] = useState('');
  const [trialData, setTrialData] = useState(null);
  const [messageTitle, setMessageTitle] = useState('');
  const [passedName, setPassedName] = useState('');
  const [passedLocation, setPassedLocation] = useState('');
  const popoverInjuryLevel = usePopoverState();
  const popoverInjurySeverity = usePopoverState();
  const popoverTimeSinceInjury = usePopoverState();
  const { t } = useTranslation('trials');

  const [trialId] = useQueryParams('trialId');
  const messageInputText = `Dear ${messageTitle},

I am interested in getting more information about the clinical trial called ${trialData?.trial?.brief_title} and would like to discuss my eligibility to participate in it.

Please get in touch with me as soon as possible.

Looking forward to hearing from you soon.
Kind regards.`;

  const checkbox = useCheckboxState({ state: false });
  const [trialLocations, setTrialLocations] = useState([]);
  const captchaRef = useRef(null);

  const timeSinceInjury = [
    {
      id: 1,
      label: 'Any',
      value: 'any',
    },
    {
      id: 2,
      label: 'Under 48h',
      value: 'under-48h',
    },
    {
      id: 3,
      label: '48h - 6 mon',
      value: '48h-6mon',
    },
    {
      id: 4,
      label: 'over 6 mon',
      value: 'over-6mon',
    },
  ];

  const userLocation = {
    latitude: contextForm?.values?.location?.value?.lat,
    longitude: contextForm?.values?.location?.value?.lng,
  };
  const fetchTrialLocations = async (lat, long) => {
    const { data } = await Client.trialLocations.get(trialId, lat, long);
    setTrialLocations(data);
  };

  const fetchTitle = async () => {
    const { data } = await Client.userTrial.get(trialId);
    setTrialData(data);
  };

  const requestUserLocation = () => {
    navigator.geolocation.getCurrentPosition(position => {
      contextForm.update('location', {
        value: {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        },
      });
      fetchTrialLocations(position.coords.latitude, position.coords.longitude);
    });
  };

  useEffect(() => {
    setLocationName(formValues?.location_name);
    fetchTrialLocations();
    fetchTitle();
  }, []);

  useLayoutEffect(() => {
    if (!userLocation.latitude && locationName) {
      requestUserLocation();
    }
    if (userLocation.latitude && trialLocations.length === 0) {
      fetchTrialLocations(userLocation.latitude, userLocation.longitude);
    }
  }, [userLocation, requestUserLocation, fetchTrialLocations, trialLocations]);

  const contactLocations = {
    closestLocation: trialLocations[0],
    preSelectedLocation: trialLocations?.find(
      trialLocation => trialLocation.recruitment_status === 'recruiting',
    ),
    recruitingLocations: trialLocations?.filter(
      trialLocation => trialLocation.recruitment_status === 'recruiting',
    ),
  };

  const prefill = contactLocations?.preSelectedLocation?.id;

  const form = useFormState({
    values: {
      trial_location_id: prefill,
      data_privacy: checkbox.state,
      message: '',
      gender: '',
      age: '',
      injuryLevel: formValues?.injuryLevel ?? [],
      severity: formValues?.severity ?? null,
      time_since_injury: formValues?.time_since_injury ?? null,
      email: '',
      phone: '',
      captcha_token: '',
    },
    onValidate: values => {
      let errors = {};
      if (!values.trial_location_id) {
        errors = {
          ...errors,
          trial_location_id: renderInputErrorFor('Please enter trial location'),
        };
      }
      if (!values.injuryLevel.length) {
        errors = {
          ...errors,
          injuryLevel: renderInputErrorFor('Please enter injury level'),
        };
      }
      if (!values.severity) {
        errors = {
          ...errors,
          severity: renderInputErrorFor('Please enter severity'),
        };
      }
      if (!values.time_since_injury) {
        errors = {
          ...errors,
          time_since_injury: renderInputErrorFor(
            'Please enter time since injury',
          ),
        };
      }
      if (!values.email) {
        errors = {
          ...errors,
          email: renderInputErrorFor('Please enter email'),
        };
      }
      if (!values.data_privacy) {
        errors = {
          ...errors,
          data_privacy: (
            <div className={styles.errorWrapper}>
              <IconWrapper
                Icon={IconComponents.SmallWarningCircle}
                className={styles.smallWarningCircle}
              />
              Please confirm the terms of service and data privacy statement
            </div>
          ),
        };
      }
      if (!values.captcha_token) {
        errors = {
          ...errors,
          captcha_token: renderInputErrorFor('Please complete captcha'),
        };
      }
      if (Object.keys(errors).length) {
        throw errors;
      }
    },
    onSubmit: async (values, errors) => {
      captchaRef.current.resetCaptcha();

      try {
        await Client.trialContact.post(trialId, values);
        navigate(`/message-sent`, {
          replace: false,
          state: { trialData, form: values, passedName, passedLocation },
        });
      } catch (e) {
        throw errors;
      }
    },
  });

  const handleVerificationSuccess = token => {
    form.update('captcha_token', token);
  };
  useEffect(() => {
    form.update('trial_location_id', prefill);
  }, [prefill]);

  useEffect(() => {
    form.update('message', messageInputText);
  }, [messageInputText]);

  useEffect(() => {
    const currentSelection = contactLocations?.recruitingLocations?.find(
      location => location.id === form.values.trial_location_id,
    );

    setMessageTitle(currentSelection?.contact?.name);
    setPassedName(currentSelection?.contact.name);
    setPassedLocation(currentSelection?.name);
  }, [form]);

  const messageLabel = (
    <Heading theme="primary" size="h3">
      Your message
    </Heading>
  );

  const checkBoxLabel = (
    <span>
      I confirm I have read and agree to the{' '}
      <Link
        asType="external"
        variant="noUnderline"
        className={styles.confirmLink}
        to="/terms-of-service"
        target="_blank"
      >
        terms of service
      </Link>{' '}
      and the{' '}
      <Link
        asType="external"
        variant="noUnderline"
        className={styles.confirmLink}
        to="/data-privacy"
        target="_blank"
      >
        data privacy statement
      </Link>
      .
    </span>
  );

  const submitArea = (
    <div className={styles.submitButton}>
      <FormSubmitButton
        as={Button}
        theme="primary"
        size="default"
        {...form}
        isFullWidth
      >
        Send message
      </FormSubmitButton>
    </div>
  );

  const briefTitle = trialData ? trialData.trial.brief_title : '';
  const shortenedBriefTitle =
    briefTitle.length > 55 ? `${briefTitle.slice(0, 52)}...` : briefTitle;

  return (
    <Layout
      title={`Spinal cord injury trial get in contact: ${shortenedBriefTitle}`}
      onlyShowSearchIcon
      isContentpage
    >
      <Container size="xl">
        <div className={styles.backward}>
          <IconWrapper
            Icon={IconComponents.SmallArrowLongLeftIcon}
            alt="icon"
            className={styles.arrowIcon}
          />
          <Link
            to={`/trials/${trialId}`}
            size="100"
            type="ui"
            variant="noUnderline"
            className={styles.link}
          >
            Back to trial
          </Link>
        </div>
        <div className={styles.contactHeading}>
          <div className={styles.iconWrapper}>
            <IconWrapper
              Icon={IconComponents.LargeWriteLetterIcon}
              alt="icon"
              className={styles.largeWriteLetter}
            />
          </div>
          <Heading theme="primary" align="center" size="h1big" noSpacing>
            Get in touch
          </Heading>
        </div>
        <div className={styles.contactTextWrapper}>
          <Content noSpacing size="md">
            We’ll send your inquiry through our <strong>secure form</strong> and
            will not store any of your personal data.
          </Content>
        </div>
      </Container>
      <Container size="full">
        <div className={styles.formContentWrapper}>
          <Form submitArea={submitArea} form={form}>
            <div className={styles.trialTitle}>
              <Heading theme="primary" size="h3">
                {trialData?.trial?.brief_title}
              </Heading>
            </div>
            <Locations
              {...contactLocations}
              form={form}
              locationName={locationName}
              unit={formValues?.unit}
            />
            {/* message input */}
            <div className={styles.textInputWrapper}>
              <Textarea
                {...form}
                name="message"
                label={messageLabel}
                variant="closed"
                theme="primary"
                defaultValue={messageInputText}
                rows={10}
              />
            </div>
            {/* details form */}
            <Heading theme="primary" size="h3">
              Your details
            </Heading>
            <FormMessage
              {...form}
              name="userDetail"
              className={styles.errorMessage}
            />
            <div>
              <p>Gender</p>
              <FormRadioGroup
                {...form}
                name="gender"
                className={styles.genderRadioButtons}
              >
                <div className={styles.genderRadioButtonWrapper}>
                  <FormRadio
                    as={Radio}
                    {...form}
                    name="gender"
                    value=""
                    label="Not specified"
                    checked
                  />
                </div>
                <div className={styles.genderRadioButtonWrapper}>
                  <FormRadio
                    as={Radio}
                    {...form}
                    name="gender"
                    value="m"
                    label="Male"
                  />
                </div>
                <div className={styles.genderRadioButtonWrapper}>
                  <FormRadio
                    as={Radio}
                    {...form}
                    name="gender"
                    value="f"
                    label="Female"
                  />
                </div>
                <div className={styles.genderRadioButtonWrapper}>
                  <FormRadio
                    as={Radio}
                    {...form}
                    name="gender"
                    value="d"
                    label="Diverse"
                  />
                </div>
              </FormRadioGroup>
            </div>
            <div className={styles.inputWrapper}>
              <div className={styles.inputGroup}>
                <div className={styles.ageInput}>
                  <InputField
                    {...form}
                    name="age"
                    label="Age"
                    inputmode="numeric"
                    noMessage
                  />
                  <FormMessage {...form} name="age" />
                </div>
                <div className={styles.inputGroupField}>
                  <Selector
                    popover={popoverInjuryLevel}
                    label={
                      <>
                        {form.values.injuryLevel.length > 0 && (
                          <span>
                            {form.values.injuryLevel[0]}
                            {form.values.injuryLevel.length > 1 && (
                              <>
                                <span>&nbsp;to</span>{' '}
                                {form.values.injuryLevel[1]}
                              </>
                            )}
                          </span>
                        )}
                      </>
                    }
                    name="Injury level*"
                    isEmpty={form.values.injuryLevel.length === 0}
                    hasReset={form.values.injuryLevel.length > 0}
                    initialState
                  >
                    <InjuryLevel
                      form={form}
                      popover={popoverInjuryLevel}
                      hideAnyButton
                    />
                  </Selector>
                  <FormMessage {...form} name="injuryLevel" />
                </div>
                <div
                  className={classNames(
                    styles.inputGroupField,
                    styles.injurySeverityInput,
                  )}
                >
                  <Selector
                    popover={popoverInjurySeverity}
                    label={
                      form.values?.severity && (
                        <span>{t(`severity.${form.values?.severity}`)}</span>
                      )
                    }
                    name="Severity*"
                    shortName="severity"
                    isEmpty={!form.values?.severity}
                    hasReset={form.values.severity !== null}
                    initialState
                  >
                    <InjurySeverity
                      form={form}
                      popover={popoverInjurySeverity}
                      hideOptionAny
                    />
                  </Selector>
                  <FormMessage {...form} name="severity" />
                </div>
              </div>
              <div className={styles.inputBox}>
                <Selector
                  shortName="since injury"
                  popover={popoverTimeSinceInjury}
                  label={
                    <>
                      {form.values.time_since_injury !== null && (
                        <span className={styles.text}>
                          {
                            timeSinceInjury.find(
                              val =>
                                val.value === form.values?.time_since_injury,
                            )?.label
                          }
                        </span>
                      )}
                    </>
                  }
                  name="Time since injury*"
                  isEmpty={!form.values?.time_since_injury}
                  hasReset={form.values.time_since_injury !== null}
                  initialState
                >
                  <TimeSinceInjury
                    form={form}
                    popover={popoverTimeSinceInjury}
                    hideOptionAny
                  />
                </Selector>
                <FormMessage {...form} name="time_since_injury" />
              </div>
            </div>
            <div className={styles.formGroupEmailPhone}>
              <div>
                <InputField
                  {...form}
                  name="email"
                  label="Email address*"
                  inputmode="email"
                  noMessage
                />
                <FormMessage {...form} name="email" />
              </div>
              <div>
                <InputField
                  {...form}
                  name="phone"
                  inputmode="tel"
                  type="tel"
                  label="Phone"
                  noMessage
                />
                <FormMessage {...form} name="phone" />
              </div>
            </div>
            <FormCheckbox
              {...form}
              as={Checkbox}
              name="data_privacy"
              label={checkBoxLabel}
              align="center"
              className={styles.privacyCheckbox}
            />
            <FormMessage {...form} name="data_privacy" />
            <div className={styles.captcha}>
              <HCaptcha
                sitekey="6e0af278-a133-4f72-8269-6c8d04d2a061"
                onVerify={handleVerificationSuccess}
                onExpire={() => handleVerificationSuccess('')}
                ref={captchaRef}
              />
              <FormMessage {...form} name="captcha_token" />
            </div>
          </Form>
        </div>
      </Container>
    </Layout>
  );
};
export default ContactTemplate;
