import styles from './Radio.module.scss';
import React from 'react';
import { Radio as BaseRadio } from 'reakit/Radio';
import { Clickable } from 'reakit/Clickable';
import classNames from 'classnames';
import PropTypes from 'prop-types';

/**
 * Base Radio ontop of reakit - styling only
 */
const Radio = React.forwardRef(({ id, label, variant, ...other }, ref) => (
  <label
    id={id ? `${id}-label` : null}
    htmlFor={id}
    className={classNames(styles.container, styles[variant])}
  >
    <BaseRadio
      id={id}
      ref={ref}
      className={styles.input}
      {...other}
      tabIndex="-1"
    />
    <Clickable as="span" className={classNames(styles.circle, styles[variant])}>
      <div className={styles.dot} />
    </Clickable>
    {label}
  </label>
));
Radio.propTypes = {
  variant: PropTypes.oneOf(['horizontal', '']),
};
Radio.defaultProps = {
  variant: '',
};

export default Radio;
