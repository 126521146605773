import styles from './Selector.module.scss';
import React from 'react';
import { Button as BaseButton } from 'reakit/Button';
import IconComponents from '@moonshineragency/ui/src/components/IconComponents/IconComponents';
import { Popover, PopoverDisclosure } from 'reakit/Popover';
import classNames from 'classnames';

const Selector = ({
  shortName,
  children,
  name,
  popover,
  hasReset,
  label,
  isEmpty,
  hasManualTrigger,
  initialState,
}) => {
  const ItemTag = !hasManualTrigger ? PopoverDisclosure : 'div';

  return (
    <div className={styles.item}>
      {popover && (
        <Popover className={styles.popOver} {...popover} aria-label={name}>
          {children}
        </Popover>
      )}
      <ItemTag className={styles.selectorDisclosure} {...popover}>
        {/** check polite */}
        <span aria-label="You selected" aria-live="polite">
          <div className={styles.label}>{name}</div>
          <div className={styles.dropdownWrapper}>
            <div className={styles.textLabel}>
              {label}
              {isEmpty === true && (
                <>
                  {initialState ? (
                    <div className={styles.emptyValue}>Select</div>
                  ) : (
                    <div className={styles.emptyValue}>Any</div>
                  )}
                </>
              )}
              {shortName && (
                <span className={styles.shortName}>{shortName}</span>
              )}
            </div>
            {popover && (
              <BaseButton
                className={classNames(styles.resetButton, {
                  [styles.hide]: !hasReset || !popover.visible,
                })}
                disabled={!hasReset || !popover.visible}
                aria-label="Reset Injury Level"
              >
                <IconComponents.SmallCrossXIcon />
              </BaseButton>
            )}
            {popover && (
              <PopoverDisclosure
                {...popover}
                disabled={popover.visible}
                className={classNames(styles.expandButton, {
                  [styles.hide]: popover.visible,
                })}
                tabIndex={-1}
              >
                <IconComponents.SmallArrowDown
                  className={styles.expandButtonIcon}
                  onClick={() => popover.show()}
                />
              </PopoverDisclosure>
            )}
          </div>
        </span>
      </ItemTag>
    </div>
  );
};

export default Selector;
