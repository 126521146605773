import styles from './Locations.module.scss';
import TemplateLocation from './TemplateLocation';
import React, { useState } from 'react';
import { unstable_FormRadioGroup as FormRadioGroup } from 'reakit/Form';
import Button from '@moonshineragency/ui/src/components/Button/Button';

const Locations = ({
  closestLocation,
  recruitingLocations,
  form,
  locationName,
  unit,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpanded = () => setIsExpanded(!isExpanded);
  const isDisabled = closestLocation?.recruitment_status !== 'recruiting';
  const slicedLocations = isDisabled
    ? recruitingLocations.slice(0, 2)
    : recruitingLocations.slice(0, 3);
  const locations = isExpanded ? recruitingLocations : slicedLocations;

  const renderButton = label => {
    return (
      <div className={styles.link}>
        <Button
          theme="link"
          onClick={toggleExpanded}
          className={styles.linkButton}
        >
          {label}
        </Button>
      </div>
    );
  };
  return (
    <div className={styles.institutContainer}>
      {/* closest location is always visible on first render */}
      {/* isDisabled when first location is not recruiting */}
      <div className={!isDisabled && isExpanded ? styles.hide : null}>
        {/* only visible at closest location which is not recruiting */}
        {isDisabled && (
          <div className={styles.warningWrapper}>
            <span className={styles.warningCircle} />
            <div className={styles.warningText}>
              The trial at your preferred location is not recruiting at the
              moment, but you can get in touch with one of the following trial
              locations instead.
            </div>
          </div>
        )}
      </div>
      <FormRadioGroup {...form} name="trial_location_id">
        {locations?.map(recruitingLocation => {
          return (
            <TemplateLocation
              location={recruitingLocation}
              form={form}
              key={recruitingLocation.id}
              locationName={locationName}
              unit={unit}
            />
          );
        })}
      </FormRadioGroup>
      {recruitingLocations?.length > 3 && (
        <>
          {isExpanded ? renderButton('Show less') : renderButton('Show more')}
        </>
      )}
    </div>
  );
};
export default Locations;
