const useQueryParams = name => {
  if (typeof window === 'undefined') {
    return [null, () => {}];
  }
  // eslint-disable-next-line
  const params = new URLSearchParams(window.location.search);
  return [
    params.get(name),
    value => {
      params.set(name, value);
    },
  ];
};
export default useQueryParams;
