import styles from './Checkbox.module.scss';
import Content from '@moonshineragency/ui/src/components/Content/Content';
import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox as BaseCheckbox } from 'reakit/Checkbox';
import classNames from 'classnames';
import IconWrapper from '@moonshineragency/ui/src/components/IconWrapper/IconWrapper';
import { ReactComponent as CheckmarkIcon } from '@moonshineragency/ui/src/assets/tokens/icons/icons-small/xSmallCheck.svg';

/**
 * Base Checkbox ontop of reakit - styling only
 */
const Checkbox = React.forwardRef(
  (
    { id, name, label, children, align, withParser, className, ...checkbox },
    ref,
  ) => {
    return (
      <label
        id={id ? `${id}-label` : null}
        htmlFor={id}
        className={classNames(styles.container, styles[align], className)}
      >
        <BaseCheckbox
          id={id}
          name={name}
          ref={ref}
          className={styles.checkbox}
          {...checkbox}
        />
        <div className={styles.checkmark}>
          <IconWrapper Icon={CheckmarkIcon} />
        </div>
        <div>
          {withParser ? (
            <Content noSpacing withParser={withParser}>
              {label}
            </Content>
          ) : (
            label
          )}
        </div>
      </label>
    );
  },
);
Checkbox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
  align: PropTypes.string,
};
Checkbox.defaultProps = {
  label: '',
  children: null,
  align: 'center',
};
export default Checkbox;
