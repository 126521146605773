import styles from './Textarea.module.scss';
import React from 'react';
import {
  unstable_FormLabel as FormLabel,
  unstable_FormInput as FormInput,
  unstable_FormMessage as FormMessage,
} from 'reakit/Form';
import classNames from 'classnames';

/**
 * Base Textarea - styling only
 */
const Textarea = React.forwardRef(
  ({ label, name, className, inputClassName, placeholder, ...form }, ref) => (
    <div className={classNames(styles.container, className)}>
      <FormLabel {...form} className={styles.label} name={name}>
        {label}
      </FormLabel>
      <FormInput
        {...form}
        className={classNames(styles.input, inputClassName)}
        ref={ref}
        name={name}
        placeholder={placeholder}
        as="textarea"
      />
      <FormMessage {...form} className={styles.message} name={name} />
    </div>
  ),
);
export default Textarea;
